import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultAddToDictionary,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const devicesSlice = createSlice({
  name: "devices",
  initialState: {
    ...defaultInitialState,
    selectedToken: null,
    selectedContract: null,
    selectedDashboardElement: "button-start",
  },
  reducers: {
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    showWithPairingToken: defaultShow,
    create: defaultCreate,
    createSuccess: (state, action) => {
      defaultCreateSuccess(state, action);
      state.showId = action.payload.id || null;
    },
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    pair: (state, action) => {
      state.pairing = true;
      state.pairingCode = action.payload.code;
      state.paired = false;
    },
    pairSuccess: (state, action) => {
      state.dictionary[action.payload.id] = action.payload;
      state.createError = null;
      state.errorsObject = null;
      state.pairing = false;
      state.paired = true;
      state.showId = action.payload.id;
    },
    pairFail: (state, action) => {
      state.pairing = false;
      state.pairingCode = null;
      state.paired = false;
      state.pairError = action.payload.message;
    },
    status: (state, action) => {
      state.fetchingStatus = true;
      state.fetchedStatus = false;
      state.statusError = null;
    },
    statusSuccess: (state, action) => {
      state.dictionary[action.payload.id] = action.payload;
      state.fetchingStatus = false;
      state.fetchedStatus = true;
      state.showId = action.payload.id;
    },
    statusFail: (state, action) => {
      state.fetchingStatus = false;
      state.fetchedStatus = false;
      state.statusError = action.payload.message;
    },
    disconnect: (state, action) => {
      state.disconnecting = true;
      state.disconnected = false;
      state.disconnectError = null;
    },
    disconnectSuccess: (state, action) => {
      state.dictionary[action.payload.id] = action.payload;
      state.disconnecting = false;
      state.disconnected = true;
      state.showId = action.payload.id;
    },
    disconnectFail: (state, action) => {
      state.disconnecting = false;
      state.disconnected = false;
      state.disconnectError = action.payload.message;
    },
    setPausedFromDevice: (state, action) => {
      state.pausedFromDevice = action.payload;
    },
    setSelectedToken: (state, action) => {
      state.selectedToken = action.payload;
    },
    setSelectedContract: (state, action) => {
      state.selectedContract = action.payload;
    },
    setSelectedDashboardElement: (state, action) => {
      state.selectedDashboardElement = action.payload;
    },
  },
});
export const {
  show: showDevice,
  showWithPairingToken: showDeviceWithPairingToken,
  update: updateDevice,
  create: createDevice,
  pair: pairDevice,
  status: fetchDeviceStatus,
  disconnect: disconnectDevice,
  setPausedFromDevice,
  setSelectedToken,
  setSelectedContract,
  setSelectedDashboardElement,
} = devicesSlice.actions;

export const actions = devicesSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const devices = (state) =>
  state.devices.idsList.map((id) => state.devices.dictionary[id]);
export const device = (state) =>
  state.devices.showId && state.devices.dictionary[state.devices.showId];
export const deviceAttributes = (state) => device(state)?.attributes;
export const deviceRelationships = (state) => device(state)?.relationships;
export const deviceId = (state) => state.devices.showId;

export const getDevice = createSelector(
  (state) => state.devices.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getDeviceAttributes = createSelector(
  (state) => state.devices.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getDeviceRelationships = createSelector(
  (state) => state.devices.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const paginatedTokenIds = createSelector(
  (state) => state.devices.dictionary,
  (state) => state.tokens.dictionary,
  (state) => state.devices.selectedToken,
  (state) => state.devices.showId,
  (_, filter) => filter,
  (dictionary, tokensDictionary, selectedToken, id, { filter }) => {
    const MAX_TOKENS_PER_PAGE = 24;

    let tokens = [];
    if (filter === "selected") {
      tokens = Object.values(tokensDictionary)?.filter(
        (token) => !!token?.attributes.visible
      );
    } else if (filter === "featured") {
      tokens = Object.values(tokensDictionary)?.filter(
        (token) => !!token?.attributes.featured
      );
    } else {
      tokens = dictionary[id]?.relationships?.tokens?.data;
    }

    const tokenIds = tokens?.map((token) => token.id);

    if (!selectedToken) return tokenIds?.slice(0, MAX_TOKENS_PER_PAGE);

    const selectedTokenIndex = tokenIds?.indexOf(selectedToken);

    const currentPage =
      Math.floor(selectedTokenIndex / MAX_TOKENS_PER_PAGE) + 1;

    const isLastTwelveTokens =
      selectedTokenIndex >=
      (currentPage - 1) * MAX_TOKENS_PER_PAGE + MAX_TOKENS_PER_PAGE - 12;

    if (isLastTwelveTokens) {
      return tokenIds?.slice(0, MAX_TOKENS_PER_PAGE * (currentPage + 1));
    }
    return tokenIds?.slice(0, MAX_TOKENS_PER_PAGE * currentPage);
  }
);

export const paginatedContractIds = createSelector(
  (state) => state.devices.dictionary,
  (state) => state.devices.selectedContract,
  (state) => state.devices.showId,
  (dictionary, selectedContract, id) => {
    const MAX_CONTRACTS_PER_PAGE = 24;
    const contractIds = dictionary[id]?.relationships?.contracts?.data?.map(
      (contract) => contract.id
    );

    if (!selectedContract) return contractIds?.slice(0, MAX_CONTRACTS_PER_PAGE);

    const selectedContractIndex = contractIds?.indexOf(selectedContract);

    const currentPage =
      Math.floor(selectedContractIndex / MAX_CONTRACTS_PER_PAGE) + 1;

    const isLastTwelveContracts =
      selectedContractIndex >=
      (currentPage - 1) * MAX_CONTRACTS_PER_PAGE + MAX_CONTRACTS_PER_PAGE - 12;

    if (isLastTwelveContracts) {
      return contractIds?.slice(0, MAX_CONTRACTS_PER_PAGE * (currentPage + 1));
    }
    return contractIds?.slice(0, MAX_CONTRACTS_PER_PAGE * currentPage);
  }
);

export const getDashboardElements = createSelector(
  (state) =>
    state.devices.showId
      ? state.devices.dictionary[state.devices.showId]
      : null,
  (state) => state.contracts.idsList,
  (state) => state.tokens.idsList,
  (state) => state.tokens.dictionary,
  (_, options) => options,
  (device, featuredContracts, tokens, tokensDictionary, options) => {
    if (!device) return [];
    const { searching, isSearchVisible, tokenFilter } = options;
    const deviceContracts =
      device?.relationships?.contracts?.data?.map((el) => el.id) || [];
    let deviceTokens = [];

    if (tokenFilter === "selected") {
      deviceTokens =
        Object.values(tokensDictionary)
          ?.filter((token) => !!token?.attributes.visible)
          ?.map((token) => token.id) || [];
    } else if (tokenFilter === "featured") {
      deviceTokens =
        Object.values(tokensDictionary)
          ?.filter((token) => !!token?.attributes.featured)
          ?.map((token) => token.id) || [];
    } else {
      deviceTokens =
        device?.relationships?.tokens?.data?.map((el) => el.id) || [];
    }
    const visibleTokens = searching ? tokens : deviceTokens;
    let elements = ["button-start", "button-settings"];
    if (featuredContracts?.length) {
      elements.push(
        ...featuredContracts.map((contract) => `featuredContract-${contract}`)
      );
    }
    if (deviceContracts?.length) {
      elements.push(
        ...deviceContracts.map((contract) => `contract-${contract}`)
      );
    }
    if (isSearchVisible) elements.push("input-search");
    elements.push("button-selected");
    elements.push("button-featured");
    elements.push("button-all");
    elements.push("button-search");
    if (visibleTokens?.length) {
      elements.push(...visibleTokens.map((token) => `token-${token}`));
    }
    return elements;
  }
);

export default devicesSlice.reducer;
